@import url('https://fonts.googleapis.com/css2?family=Spectral:wght@700&display=swap');


font-family: 'Overpass', sans-serif;
* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin:0;
  padding: 0;
  --type-first:Helvetica, Arial, sans-serif;
  --type-second: 'Spectral', georgia;
  font-family: var(--type-first);
}

h1,h2,h3,h4,p{
  margin:0px;
}

ul, li{
  list-style: none;
  padding:0px;
  margin:0px;
}

img {
  display: block;
  max-width: 100%;
}

a {
  text-decoration: none;
  color:#333;
}

.container{
  max-width: 70rem;
  padding:0 1rem;
  margin:0 auto;
}

.mainContainer {
  margin-top:2rem;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 80vh;
}



