@import url(https://fonts.googleapis.com/css2?family=Spectral:wght@700&display=swap);
font-family: 'Overpass', sans-serif;
* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin:0;
  padding: 0;
  --type-first:Helvetica, Arial, sans-serif;
  --type-second: 'Spectral', georgia;
  font-family: var(--type-first);
}

h1,h2,h3,h4,p{
  margin:0px;
}

ul, li{
  list-style: none;
  padding:0px;
  margin:0px;
}

img {
  display: block;
  max-width: 100%;
}

a {
  text-decoration: none;
  color:#333;
}

.container{
  max-width: 70rem;
  padding:0 1rem;
  margin:0 auto;
}

.mainContainer {
  margin-top:2rem;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 80vh;
}




.Header_header__2HMVR {
  box-shadow: 0px 2px 2px rgba(0,0,0,.1);
  position: fixed;
  width: 100%;
  z-index: 100;
  background:#fff;
  top:0;
}

.Header_nav__1Haoe {
  display:flex;
  justify-content: space-between;
  align-items: center;
  height:4rem;
}

.Header_nav__1Haoe .Header_logo__3O4nO {
  padding:0.5rem 0;
}

.Header_nav__1Haoe .Header_logo__3O4nO img {
  width: 200px;
  transition: ease 0.3s;
}

.Header_nav__1Haoe .Header_logo__3O4nO img:hover {
  width: 210px;
}

.Header_nav__1Haoe ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}


.Header_nav__1Haoe li:not(:last-child) {
  margin-right: 20px;
}

.Header_nav__1Haoe ul li a {
  display: block;
  font-family: var(--type-second);
  color:#2e5aa3;
  font-weight: bold;
  position: relative;
  font-size: 14px;
  cursor: pointer;
}

.Header_nav__1Haoe ul li a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: -3px;
  left: 0;
  background-color: #fb1;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

.Header_nav__1Haoe ul li a:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

.Header_nav__1Haoe ul li a.Header_active__3-xOU {
  border-bottom : 3px solid #fb1;
}

.Header_nav__1Haoe .Header_botao__2VyRp {
  outline: none;
  border: 2px solid #fb1;
  background: transparent;
  padding: 10px 15px;
  color:#fff;
  cursor: pointer;
  background-color:#2e5aa3;
  text-transform: uppercase;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.Header_nav__1Haoe .Header_botao__2VyRp:hover {
  -webkit-filter: brightness( 110% );
          filter: brightness( 110% );
}

.Header_navMobile__3w1qd {
  display: block;
  position: absolute;
  top:65px;
  right:2px;
  padding: 1rem 1rem;
  background: #fff;
  box-shadow: 0 1px 2px rgba(0,0,0,.2);
  border-radius: .2rem;
  transform: translateX(-15px);
  opacity: 0;
  pointer-events: none;
}

.Header_navMobile__3w1qd ul {
  flex-direction: column;
}

.Header_navMobile__3w1qd button {
  margin-top: 10px;
}

.Header_navMobileActive__GMsrC {
  transition: 0.3s;
  transform: none;
  transform: initial;
  opacity: 1;
  pointer-events: initial;
  z-index: 100;
}

.Header_navMobile__3w1qd a,
.Header_navMobile__3w1qd button {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
  width: 100%;
  border:none;
  border-bottom: 1px solid #fff;
  padding: 0.5rem 0;
  cursor: pointer;
}


.Header_mobileButton__3xEO5 {
  background: #fff;
  border-radius: .2rem;
  height: 40px;
  width: 40px;
  padding:0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  transition: .1s;
  cursor: pointer;
  border-color: #fb1;
  color:#fb1;
}
.Header_mobileButton__3xEO5::after{
  content:'';
  display:block;
  width: 1.2rem;
  height: 2px;
  border-radius: 2px;
  background: currentColor;
  box-shadow: 0 6px currentColor, 0 -6px currentColor;
  transition: 0.2s;
}

.Header_mobileButton__3xEO5:focus,
.Header_mobileButton__3xEO5:hover,
.Header_mobileButtonActive__1_Jjz {
  outline: none;
  background: #fff;
  box-shadow: 0 0 3px #fea;
}

.Header_mobileButtonActive__1_Jjz::after {
  transform: rotate(-90deg);
  width: 4px;
  height: 4px;
  box-shadow: 0 8px currentColor, 0 -8px currentColor;
}










.Home_home__3PGzA {
  margin-top:4rem;
}
.Atributos_bg__R-F-n {
  background-color: #EBF1F5;
  min-height: 120px;
}

.Atributos_atributos__38TWH {
  height: 200px;
  background-color: #2E5AA3;
  display: flex;
  justify-content: center;
  border-top: 5px solid #fb1;
}

.Atributos_atributos__38TWH  div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top:20px;
  height: 40px;
}

.Atributos_atributos__38TWH div .Atributos_texto__V6Eoi{
  color:#fff;
  line-height: 40px;
  font-family: var(--type-second);
  font-weight: bold;
}
.Atributos_atributos__38TWH div .Atributos_svg__19Wv5 {
  margin-right: 10px;
}

.Atributos_atributos__38TWH div svg > * {
  fill:#fb1;
}

.Atributos_content__1VhY3 {
  display: flex;
  flex-direction: row;
}

.Atributos_content__1VhY3 .Atributos_box__1oP_J {
  display: flex;
  flex:1 1;
  justify-content: space-between;
  border-radius: 10px;
  padding:15px;
  margin: 0 5px;
  margin-top: -90px;
  background-color: #fff;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
}

.Atributos_content__1VhY3 .Atributos_box__1oP_J .Atributos_texto__V6Eoi {
  flex-direction: column;
  flex:1 1;
}

.Atributos_content__1VhY3 .Atributos_box__1oP_J h2 {
  color:#2E5AA3;
  font-weight: bold;
  padding: 5px;
  font-family: var(--type-second);
}

.Atributos_content__1VhY3 .Atributos_box__1oP_J p {
  padding: 5px;
  margin: 5px;
  color:#555;
  font-family: var(--type-first);
  font-size: 14px;
}

.Atributos_content__1VhY3 .Atributos_image__1nzaI {
  flex:1 1;
}

.Atributos_content__1VhY3 img {
  border-radius: 5px;
  width: 100%;
}

@media (max-width:900px){
  .Atributos_atributos__38TWH div .Atributos_texto__V6Eoi {
    display: none;
  }
  .Atributos_content__1VhY3 .Atributos_box__1oP_J {
    flex-direction: column;
  }
  .Atributos_bg__R-F-n {
    padding-bottom: 20px;
  }

}

@media (max-width:40rem){
  .Atributos_content__1VhY3 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap:100px;
    gap:100px;
  }
}






h1 {
  text-align: center;
  margin-top: 15px;
  color:#555;
  position: relative;
}
h1:after{
  content: '';
  height: 3px;
  width: 50px;
  background-color:#fb1;
}

.Obras_content__2o3AI {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  gap: 10px;
  margin-top: 20px;
}

.Obras_content__2o3AI .Obras_box__vJcT2 {
  justify-content: space-between;
  margin: 0 5px;
  border:1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;

  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.Obras_content__2o3AI .Obras_box__vJcT2 .Obras_texto__2ZXVM {
  flex-direction: column;
  flex:1 1;
  margin: 5px 5px;
  padding: 10px;
}

.Obras_content__2o3AI .Obras_box__vJcT2 h2 {
  color:#2E5AA3;
  font-weight: bold;
  font-family: var(--type-second);
}

h1 {
  color:#2E5AA3;
  font-weight: bold;
  text-align: center;
  font-family: var(--type-second);
}

.Obras_content__2o3AI .Obras_box__vJcT2 p {
  padding: 2px;
  color:#555;
  font-family: var(--type-first);
  font-size: 14px;
}

.Obras_content__2o3AI .Obras_image__2O-JT {
  margin:5px;
  flex:1 1;
}

.Obras_content__2o3AI img {
  border-radius: 5px;
  width: 100%;
}

@media (max-width:900px) {
  .Obras_content__2o3AI {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width:40rem) {
  .Obras_content__2o3AI {
    grid-template-columns: repeat(1, 1fr);
  }
}
.Contato_bg__Qz4rf {
  background-color: #EBF1F5;
  margin-top:20px;
  padding-bottom: 20px;
}


h1 {
  color:#2E5AA3;
  font-weight: bold;
  text-align: center;
  padding-top: 10px;
  font-family: var(--type-second);
}

.Contato_contato__2Qehp {
  display: flex;
  justify-content: space-between;
}

.Contato_contato__2Qehp .Contato_form__371Ht {
  margin-right:10px;
  padding: 30px;
  width: 500px;
}

.Contato_contato__2Qehp .Contato_image__eiAAg {
  display: flex;
  flex:1 1;
  align-items: center;
}

.Contato_contato__2Qehp img{
  width: 100%;
  border-radius: 10px;
}

.Contato_textarea__288Ts{
  display: block;
  width: 100%;
  font-size: 1rem;
  font-family: var(--type-first);
  resize: none;
  border: 1px solid #eee;
  padding:.8rem;
  border-radius: .5rem;
  background: #fff;
  transition: 0.2s;
  margin-bottom: 10px;
}

.Contato_textarea__288Ts:focus,
.Contato_textarea__288Ts:hover {
  outline: none;
  border-color: #fb1;
  background: #fff;
  box-shadow: 0 0 0 3px #fea;
}

@media (max-width:900px) {
  .Contato_contato__2Qehp .Contato_image__eiAAg {
    display: none;
  }
  .Contato_contato__2Qehp .Contato_form__371Ht {
    flex:1 1;
  }

}




.Input_wrapper__2jYWr {
  margin-bottom:1rem;
}

.Input_input__222Qm {
  border:1px solid #eee;
  display: block;
  width: 100%;
  font-size: 1rem;
  padding: 0.8rem;
  border-radius:0.4rem;
  background: #fff;
  transition: 0.2s;
}

.Input_input__222Qm:focus,
.Input_input__222Qm:hover {
  outline: none;
  border-color:#fb1;
  background: #fff;
  box-shadow: 0 0 0 3px #fea;
}

.Input_label__3OjoL {
  display: block;
  font-size:1rem;
  line-height: 1;
  padding-bottom: 0.5rem;
}

.Input_error__2n898 {
  color:#f31;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}
.Button_button__1HrIi {
  font-size: 1rem;
  font-family: var(--type-first);
  cursor: pointer;
  border:none;
  border-radius: .4rem;
  background:#fb1;
  color:#764701;
  padding:0.8rem 1.2rem;
  box-sizing: border-box;
  transition: 0.2s;
  min-width: 8rem;
}

.Button_button__1HrIi:hover,
.Button_button__1HrIi:focus {
  outline: none;
  box-shadow: 0 0 0 3px #fea, 0 0 0 4px #fb1;
}

.Button_button__1HrIi:disabled {
  opacity: 0.5;
  cursor: wait;
}
.Footer_footer__wYSDB {
  background-color: #2E5AA3;
  border-top:3px solid #fb1;
  display: flex;
  flex-direction: column;
}

.Footer_footer__wYSDB .Footer_info__3MPpY {
  display: flex;
  height: 150px;
  align-items: center;
  width: 100%;
  justify-content: space-around;
}

.Footer_footer__wYSDB .Footer_image__2eAfR {
  width: 150px;
  cursor: pointer;
}

.Footer_footer__wYSDB .Footer_info__3MPpY .Footer_address__3gk5d {
  display: flex;
  flex-direction: row;
}

.Footer_footer__wYSDB .Footer_info__3MPpY .Footer_address__3gk5d p {
  margin-left: 15px;
  color: #fff;
}

.Footer_footer__wYSDB .Footer_info__3MPpY .Footer_address__3gk5d svg > * {
  fill:#fb1;
}

.Footer_footer__wYSDB .Footer_info__3MPpY .Footer_social__1TUeE div > * {
  fill:#fb1;
}

.Footer_footer__wYSDB .Footer_info__3MPpY .Footer_social__1TUeE .Footer_socialDiv__28zP5 {
  display: flex;
  flex-direction: row;
  margin: 10px;
  justify-content: center;
  align-items: center;
}


.Footer_footer__wYSDB .Footer_info__3MPpY .Footer_social__1TUeE p {
  margin-left:10px;
  color:#fff;
}

.Footer_footer__wYSDB .Footer_create__1lHJq {
  border-top: 2px solid #fb1;
  background-color:#2E5AA3;
  height: 40px;
}

.Footer_footer__wYSDB .Footer_create__1lHJq p {
  color: #fb1;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  line-height: 40px;
}

.Footer_footer__wYSDB .Footer_create__1lHJq a {
  text-decoration: none;
  color: #fff;
}

@media(max-width:40rem){
  .Footer_footer__wYSDB .Footer_info__3MPpY {
    flex-direction: column;
    height: auto;
    padding: 30px 0;
  }

  .Footer_footer__wYSDB .Footer_info__3MPpY .Footer_address__3gk5d {
    margin-bottom: 30px;
    margin-top:30px;
  }
}

