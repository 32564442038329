
h1 {
  text-align: center;
  margin-top: 15px;
  color:#555;
  position: relative;
}
h1:after{
  content: '';
  height: 3px;
  width: 50px;
  background-color:#fb1;
}

.content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 20px;
}

.content .box {
  justify-content: space-between;
  margin: 0 5px;
  border:1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;

  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.content .box .texto {
  flex-direction: column;
  flex:1;
  margin: 5px 5px;
  padding: 10px;
}

.content .box h2 {
  color:#2E5AA3;
  font-weight: bold;
  font-family: var(--type-second);
}

h1 {
  color:#2E5AA3;
  font-weight: bold;
  text-align: center;
  font-family: var(--type-second);
}

.content .box p {
  padding: 2px;
  color:#555;
  font-family: var(--type-first);
  font-size: 14px;
}

.content .image {
  margin:5px;
  flex:1;
}

.content img {
  border-radius: 5px;
  width: 100%;
}

@media (max-width:900px) {
  .content {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width:40rem) {
  .content {
    grid-template-columns: repeat(1, 1fr);
  }
}