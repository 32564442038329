.bg {
  background-color: #EBF1F5;
  margin-top:20px;
  padding-bottom: 20px;
}


h1 {
  color:#2E5AA3;
  font-weight: bold;
  text-align: center;
  padding-top: 10px;
  font-family: var(--type-second);
}

.contato {
  display: flex;
  justify-content: space-between;
}

.contato .form {
  margin-right:10px;
  padding: 30px;
  width: 500px;
}

.contato .image {
  display: flex;
  flex:1;
  align-items: center;
}

.contato img{
  width: 100%;
  border-radius: 10px;
}

.textarea{
  display: block;
  width: 100%;
  font-size: 1rem;
  font-family: var(--type-first);
  resize: none;
  border: 1px solid #eee;
  padding:.8rem;
  border-radius: .5rem;
  background: #fff;
  transition: 0.2s;
  margin-bottom: 10px;
}

.textarea:focus,
.textarea:hover {
  outline: none;
  border-color: #fb1;
  background: #fff;
  box-shadow: 0 0 0 3px #fea;
}

@media (max-width:900px) {
  .contato .image {
    display: none;
  }
  .contato .form {
    flex:1;
  }

}



