.footer {
  background-color: #2E5AA3;
  border-top:3px solid #fb1;
  display: flex;
  flex-direction: column;
}

.footer .info {
  display: flex;
  height: 150px;
  align-items: center;
  width: 100%;
  justify-content: space-around;
}

.footer .image {
  width: 150px;
  cursor: pointer;
}

.footer .info .address {
  display: flex;
  flex-direction: row;
}

.footer .info .address p {
  margin-left: 15px;
  color: #fff;
}

.footer .info .address svg > * {
  fill:#fb1;
}

.footer .info .social div > * {
  fill:#fb1;
}

.footer .info .social .socialDiv {
  display: flex;
  flex-direction: row;
  margin: 10px;
  justify-content: center;
  align-items: center;
}


.footer .info .social p {
  margin-left:10px;
  color:#fff;
}

.footer .create {
  border-top: 2px solid #fb1;
  background-color:#2E5AA3;
  height: 40px;
}

.footer .create p {
  color: #fb1;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  line-height: 40px;
}

.footer .create a {
  text-decoration: none;
  color: #fff;
}

@media(max-width:40rem){
  .footer .info {
    flex-direction: column;
    height: auto;
    padding: 30px 0;
  }

  .footer .info .address {
    margin-bottom: 30px;
    margin-top:30px;
  }
}
