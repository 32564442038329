.button {
  font-size: 1rem;
  font-family: var(--type-first);
  cursor: pointer;
  border:none;
  border-radius: .4rem;
  background:#fb1;
  color:#764701;
  padding:0.8rem 1.2rem;
  box-sizing: border-box;
  transition: 0.2s;
  min-width: 8rem;
}

.button:hover,
.button:focus {
  outline: none;
  box-shadow: 0 0 0 3px #fea, 0 0 0 4px #fb1;
}

.button:disabled {
  opacity: 0.5;
  cursor: wait;
}