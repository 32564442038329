.bg {
  background-color: #EBF1F5;
  min-height: 120px;
}

.atributos {
  height: 200px;
  background-color: #2E5AA3;
  display: flex;
  justify-content: center;
  border-top: 5px solid #fb1;
}

.atributos  div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top:20px;
  height: 40px;
}

.atributos div .texto{
  color:#fff;
  line-height: 40px;
  font-family: var(--type-second);
  font-weight: bold;
}
.atributos div .svg {
  margin-right: 10px;
}

.atributos div svg > * {
  fill:#fb1;
}

.content {
  display: flex;
  flex-direction: row;
}

.content .box {
  display: flex;
  flex:1;
  justify-content: space-between;
  border-radius: 10px;
  padding:15px;
  margin: 0 5px;
  margin-top: -90px;
  background-color: #fff;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
}

.content .box .texto {
  flex-direction: column;
  flex:1;
}

.content .box h2 {
  color:#2E5AA3;
  font-weight: bold;
  padding: 5px;
  font-family: var(--type-second);
}

.content .box p {
  padding: 5px;
  margin: 5px;
  color:#555;
  font-family: var(--type-first);
  font-size: 14px;
}

.content .image {
  flex:1;
}

.content img {
  border-radius: 5px;
  width: 100%;
}

@media (max-width:900px){
  .atributos div .texto {
    display: none;
  }
  .content .box {
    flex-direction: column;
  }
  .bg {
    padding-bottom: 20px;
  }

}

@media (max-width:40rem){
  .content {
    display: grid;
    grid-template-columns: 1fr;
    gap:100px;
  }
}




