.header {
  box-shadow: 0px 2px 2px rgba(0,0,0,.1);
  position: fixed;
  width: 100%;
  z-index: 100;
  background:#fff;
  top:0;
}

.nav {
  display:flex;
  justify-content: space-between;
  align-items: center;
  height:4rem;
}

.nav .logo {
  padding:0.5rem 0;
}

.nav .logo img {
  width: 200px;
  transition: ease 0.3s;
}

.nav .logo img:hover {
  width: 210px;
}

.nav ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}


.nav li:not(:last-child) {
  margin-right: 20px;
}

.nav ul li a {
  display: block;
  font-family: var(--type-second);
  color:#2e5aa3;
  font-weight: bold;
  position: relative;
  font-size: 14px;
  cursor: pointer;
}

.nav ul li a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: -3px;
  left: 0;
  background-color: #fb1;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.nav ul li a:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.nav ul li a.active {
  border-bottom : 3px solid #fb1;
}

.nav .botao {
  outline: none;
  border: 2px solid #fb1;
  background: transparent;
  padding: 10px 15px;
  color:#fff;
  cursor: pointer;
  background-color:#2e5aa3;
  text-transform: uppercase;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.nav .botao:hover {
  filter: brightness( 110% );
}

.navMobile {
  display: block;
  position: absolute;
  top:65px;
  right:2px;
  padding: 1rem 1rem;
  background: #fff;
  box-shadow: 0 1px 2px rgba(0,0,0,.2);
  border-radius: .2rem;
  transform: translateX(-15px);
  opacity: 0;
  pointer-events: none;
}

.navMobile ul {
  flex-direction: column;
}

.navMobile button {
  margin-top: 10px;
}

.navMobileActive {
  transition: 0.3s;
  transform: initial;
  opacity: 1;
  pointer-events: initial;
  z-index: 100;
}

.navMobile a,
.navMobile button {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
  width: 100%;
  border:none;
  border-bottom: 1px solid #fff;
  padding: 0.5rem 0;
  cursor: pointer;
}


.mobileButton {
  background: #fff;
  border-radius: .2rem;
  height: 40px;
  width: 40px;
  padding:0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  transition: .1s;
  cursor: pointer;
  border-color: #fb1;
  color:#fb1;
}
.mobileButton::after{
  content:'';
  display:block;
  width: 1.2rem;
  height: 2px;
  border-radius: 2px;
  background: currentColor;
  box-shadow: 0 6px currentColor, 0 -6px currentColor;
  transition: 0.2s;
}

.mobileButton:focus,
.mobileButton:hover,
.mobileButtonActive {
  outline: none;
  background: #fff;
  box-shadow: 0 0 3px #fea;
}

.mobileButtonActive::after {
  transform: rotate(-90deg);
  width: 4px;
  height: 4px;
  box-shadow: 0 8px currentColor, 0 -8px currentColor;
}









